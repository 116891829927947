<template>
    <div :class="[`notification`, `notification_${theme}`]">
        <p>
            <exclamation-triangle-icon />

            <span>
                <slot />
            </span>
        </p>

        <slot name="action" />
    </div>
</template>

<script>
    import ExclamationTriangleIcon from '@/components/icons/ExclamationTriangleIcon';

    export default {
        name: 'Notification',
        components: {
            ExclamationTriangleIcon,
        },
        props: {
            theme: {
                type: String,
                default: 'warning',
                validator: value => ['success', 'warning'].includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .notification {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-radius: 0.8rem;

        p {
            display: flex;

            span {
                display: flex;
                align-items: center;
                font-family: 'Gotham Book', sans-serif;
                font-size: 1.2rem;
                font-weight: 325;
                line-height: 1.3;
            }

            svg {
                flex: 0 0 auto;
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
            }
        }

        ::v-deep {
            > a,
            > button {
                flex-shrink: 0;
                align-self: start;
                margin-left: 1rem;
            }
        }

        &_success {
            background: linear-gradient(180deg, #15292d -8.33%, #15292d 111.67%);

            p {
                span {
                    color: #e4fbed;
                }

                svg {
                    fill: #b7e9bc;
                }
            }
        }

        &_warning {
            background-color: rgba(238, 206, 122, 0.06);

            p {
                span {
                    color: #ffd79a;
                }

                svg {
                    fill: #fabf66;
                }
            }
        }
    }

    @media screen and (min-width: 22.5em) {
        .notification {
            padding: 1.5rem;
        }
    }

    @media screen and (min-width: 30em) {
        .notification {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media screen and (min-width: 120em) {
        .notification {
            p {
                span {
                    font-size: 1.4rem;
                }

                svg {
                    width: 2.6rem;
                    height: 2.6rem;
                }
            }

            ::v-deep {
                a,
                button {
                    height: 3.6rem;
                    padding: 0 1rem;

                    span {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
</style>
