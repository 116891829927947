<template>
    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.7223 17.0778L16.3468 5.63411C15.49 4.09437 14.3046 3.25 12.9996 3.25C11.6946 3.25 10.5092 4.09437 9.65246 5.63411L3.27693 17.0778C2.47003 18.5381 2.38037 19.9387 3.02788 21.0414C3.6754 22.144 4.9505 22.75 6.62408 22.75L19.3751 22.75C21.0487 22.75 22.3238 22.144 22.9713 21.0414C23.6188 19.9387 23.5292 18.5281 22.7223 17.0778ZM12.2525 10.2036C12.2525 9.79636 12.5912 9.45861 12.9996 9.45861C13.408 9.45861 13.7467 9.79636 13.7467 10.2036L13.7467 15.1705C13.7467 15.5778 13.408 15.9156 12.9996 15.9156C12.5912 15.9156 12.2525 15.5778 12.2525 15.1705L12.2525 10.2036ZM13.7069 18.856C13.6571 18.8957 13.6073 18.9354 13.5575 18.9752C13.4977 19.0149 13.4379 19.0447 13.3782 19.0646C13.3184 19.0944 13.2586 19.1142 13.1889 19.1242C13.1291 19.1341 13.0594 19.144 12.9996 19.144C12.9398 19.144 12.8701 19.1341 12.8004 19.1242C12.7406 19.1142 12.6808 19.0944 12.6211 19.0646C12.5613 19.0447 12.5015 19.0149 12.4417 18.9752C12.3919 18.9354 12.3421 18.8957 12.2923 18.856C12.113 18.6672 12.0034 18.4089 12.0034 18.1507C12.0034 17.8924 12.113 17.6341 12.2923 17.4454C12.3421 17.4056 12.3919 17.3659 12.4417 17.3262C12.5015 17.2864 12.5613 17.2566 12.6211 17.2368C12.6808 17.207 12.7406 17.1871 12.8004 17.1772C12.9299 17.1474 13.0693 17.1474 13.1889 17.1772C13.2586 17.1871 13.3184 17.207 13.3782 17.2368C13.4379 17.2566 13.4977 17.2864 13.5575 17.3262C13.6073 17.3659 13.6571 17.4056 13.7069 17.4454C13.8862 17.6341 13.9958 17.8924 13.9958 18.1507C13.9958 18.4089 13.8862 18.6672 13.7069 18.856Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ExclamationTriangleIcon',
    };
</script>
