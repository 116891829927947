<template>
    <input-component ref="input" v-bind="{ ...$attrs, value }">
        <template #suffix>
            <button type="button" :disabled="copy.success" @click="copyToClipboard($refs.input.$refs.input, $event)">
                <component :is="iconComponent" />
            </button>
        </template>
    </input-component>
</template>

<script>
    import copyMixin from '@/mixins/copyMixin';
    import InputComponent from '@/components/inputs/InputComponent';

    export default {
        name: 'InputCopy',
        components: {
            InputComponent,
        },
        props: {
            value: [Number, String],
        },
        mixins: [copyMixin],
    };
</script>
