<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M35.814 18.814H32.5879C32.0186 12.7888 27.2112 7.96558 21.186 7.41209V4.18605C21.186 3.53767 20.6484 3 20 3C19.3516 3 18.814 3.53767 18.814 4.18605V7.41209C12.7888 7.9814 7.96558 12.7888 7.41209 18.814H4.18605C3.53767 18.814 3 19.3516 3 20C3 20.6484 3.53767 21.186 4.18605 21.186H7.41209C7.9814 27.2112 12.7888 32.0344 18.814 32.5879V35.814C18.814 36.4623 19.3516 37 20 37C20.6484 37 21.186 36.4623 21.186 35.814V32.5879C27.2112 32.0186 32.0344 27.2112 32.5879 21.186H35.814C36.4623 21.186 37 20.6484 37 20C37 19.3516 36.4623 18.814 35.814 18.814ZM20 24.934C17.28 24.934 15.066 22.72 15.066 20C15.066 17.28 17.28 15.066 20 15.066C22.72 15.066 24.934 17.28 24.934 20C24.934 22.72 22.72 24.934 20 24.934Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ConnectIcon',
    };
</script>
